/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import Figure from "../../statics/figure1.jpg";
import Jump from "../../statics/jump-skip.jpg";
import PushUp from "../../statics/push-up.jpg";
import Elderly from "../../statics/elderly.jpg";
import { Link } from "react-router-dom";
import axiosPublic from "../../common/axiosPublic";
import {
  numericDateTimeToAlphebet,
  numericDateToAlphebet,
} from "../../statics/CONST";
import theme from "../../theme";
import Guidelines from "./guidelines";

const styles = {
  cntnr: {
    mt: { xs: "3.5rem", md: "5rem" },
    px: "2rem",
    maxWidth: "75rem",
  },
  loginCntnr: {
    mb: { xs: "3rem", md: "4rem" },
  },

  title: {
    color: "primary.main",
    fontSize: { xs: "1.875rem", sd: "2.5rem" },
    fontWeight: 750,
  },
  packCntnr: {
    mt: "1.5rem",
    borderRadius: "1.5rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    p: "1.5rem",
  },
  activePackCntnr: {
    boxShadow: "inset 0 0 0 1000px rgba(4, 30, 31, 0.7)",
    color: "#fff",
    transition: "box-shadow 0.15s linear",
    "&:hover": {
      boxShadow: "inset 0 0 0 1000px rgba(4, 35, 36, 0.6), 0px 0px 10px #000 ",
    },
  },
  inactivePackCntnr: {
    boxShadow: "inset 0 0 0 1000px rgba(63, 76, 76 , 0.85)",
    color: "#fff",
  },
  packName: css`
    margin: 0;
    text-align: center;
    // font-size: 2.5rem;
    font-weight: 600;

    @media only screen and (max-width: 700px) {
      font-size: 1.7rem;
    }
    @media only screen and (min-width: 701px) {
      font-size: 2.5rem;
    }
  `,
  priceTxt: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  priceXs: {
    display: { xs: "flex", md: "none" },
    mt: "0.75rem",
  },
  priceMd: {
    display: { xs: "none", md: "flex" },
  },
  descTitle: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  aimTtl: {
    mt: "0.75rem",
  },
  equipTtl: {
    mt: { xs: "1rem", md: "0.75rem" },
  },
  desc: {
    mt: { xs: "0.5rem", md: "0.75rem" },
  },
  space: { mt: "0.35rem" },
  buttonCntnr: {
    mt: "1.5rem",
  },
  button: {
    color: "#fff",
    width: { xs: "100%", md: "17.5rem" },
    "&.Mui-disabled": {
      backgroundColor: "#666",
      color: "#fff",
    },
  },
  originalPrice: {
    textDecoration: "line-through",
  },
  discountedPrice: {
    fontSize: "1.25rem",
    fontWeight: 750,
    mr: "0.5rem",
  },
  mark: css`
    color: #fff;
    margin: 0 0.2rem;
    padding: 0 0.2rem;
  `,
  activeMark: css`
    background-color: #048e94;
  `,
  inactiveMark: css`
    background-color: #444444;
  `,
  psPrnt: {
    mt: "1.5rem",
    p: "1.5rem",
    backgroundColor: "#73208a",
    borderRadius: "1.5rem",
    color: "#fff",
  },
  psCntnr: {
    // mt: "1.5rem",
  },
  link: css`
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    @media only screen and (min-width: 900px) {
      width: auto;
    }
  `,
  dateCntnr: {
    mb: { xs: "1rem", md: 0 },
    justifyContent: { xs: "center", md: "flex-start" },
    textAlign: { xs: "center", md: "left" },
  },
  pageIdCntnr: {
    mb: { xs: "1rem", md: 0 },
    textAlign: "center",
    alignContent: "center",
    display: "flex",
    // fontFamily: "Arial, sans-serif !important",
  },
  pageIdEngTxt: {
    fontFamily: "Arial, sans-serif !important",
    pl: "0.5rem",
  },
  telLink: css`
    text-decoration: none;
    margin: 0 0.25rem;
    // color: ${theme.palette.primary.dark};
    color: #7cf2f7;
    text-align: right;
    direction: rtl;
  `,
};

export default function Packages(props) {
  const [packsState, setPacksState] = useState({
    fullbody: "inactive",
    aerobic: "inactive",
    // corrective: "active",
  });
  const [packsData, setPacksData] = useState({
    fullbody: {
      purchase_start_time: "",
      purchase_end_time: "",
    },
    aerobic: {
      purchase_start_time: "",
      purchase_end_time: "",
    },
    // corrective: {
    //   purchase_start_time: "",
    //   purchase_end_time: "",
    // },
  });
  const [packsName, setPacksName] = useState([
    "fullbody",
    "aerobic",
    // "corrective",
  ]);

  const handleFetchData = () => {
    packsName.map(async (packName) => {
      await axiosPublic
        .get(`packs/active_pack/?category=${packName}`)
        .then((res) => {
          if (res.status === 200) {
            setPacksState((prevState) => {
              return { ...prevState, [packName]: res.data.state };
            });
            setPacksData((prevState) => {
              return { ...prevState, [packName]: res.data.data };
            });
          }
        })
        .catch((err) => {
          setPacksState((prevState) => {
            return { ...prevState, [packName]: "inactive" };
          });
        });
    });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <Grid2 container xs={12} justifyContent="center">
      <Grid2
        container
        xs={12}
        justifyContent="center"
        alignContent="flex-start"
        sx={styles.cntnr}
      >
        <Grid2
          container
          xs={12}
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={styles.loginCntnr}
        >
          <Grid2 xs={12} md sx={{ textAlign: { xs: "center", md: "inherit" } }}>
            اگر قبلا در دوره‌ها ثبت‌نام کردی، از این قسمت وارد حساب کاربری خودت
            شو، و دوره‌های فعالت رو ببین. در غیر این صورت، از قسمت پایین برای
            ثبت نام اقدام کن.
          </Grid2>
          <Grid2 sx={{ mt: { xs: "1rem", md: 0 } }} xs={12} md={"auto"}>
            <Link to={props.isLoggedIn ? "/dashboard" : "/register?t=login"}>
              <Button
                id="packages"
                variant="contained"
                endIcon={<LoginIcon />}
                sx={styles.button}
              >
                ورود به حساب کاربری
              </Button>
            </Link>
          </Grid2>
        </Grid2>

        {/* <Grid2 container xs={12} sx={styles.title} id="packages"> */}
        <Grid2
          container
          xs={12}
          sx={[styles.title, { display: { xs: "none", sm: "flex" } }]}
        >
          تو شروع کن، باقی‌شو بسپر به من!
        </Grid2>
        <Grid2
          container
          xs={12}
          sx={[styles.title, { display: { xs: "flex", sm: "none" } }]}
        >
          تو شروع کن،
          <br />
          باقی‌شو بسپر به من!
        </Grid2>

        <Grid2
          container
          xs={12}
          sx={[
            styles.packCntnr,
            { backgroundImage: `url(${PushUp})` },
            packsState.fullbody === "active" && packsState.aerobic === "active"
              ? styles.activePackCntnr
              : styles.inactivePackCntnr,
          ]}
          alignContent="flex-start"
          columnGap={6}
        >
          <Grid2
            container
            xs={12}
            alignItems="center"
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
          >
            <Grid2
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                whiteSpace: "pre-wrap",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <Grid2 xs={12} md>
                <h2 css={styles.packName}>
                  {packsData.aerobic.name
                    ? "فول بادی + " + packsData.aerobic.name
                    : "فول بادی + هوازی"}
                </h2>
              </Grid2>
              <Grid2
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  fontWeight: 600,
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  ml: { md: "0.5rem" },
                }}
              >
                + رژیم غذایی رایگان
              </Grid2>
            </Grid2>
            <Grid2
              container
              alignItems="center"
              sx={[styles.offPrice, styles.priceMd]}
            >
              <Grid2 sx={styles.discountedPrice}>
                <mark
                  css={[
                    styles.mark,
                    packsState.fullbody === "active" &&
                    packsState.aerobic === "active"
                      ? styles.activeMark
                      : styles.inactiveMark,
                  ]}
                >
                  599 هزار تومان
                </mark>
              </Grid2>
              <Grid2 sx={styles.originalPrice}>998 هزار تومان</Grid2>
            </Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={styles.desc}>
              برای فرم‌دهی و چربی‌سوزی حداکثری و اصلاح فرم بدنی هر دو دوره رو
              شرکت کن.
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              اگر توی هر دو باشگاه شرکت کنی، یه تخفیف بزرگ از طرف من به شما تعلق
              می‌گیره :)
            </Grid2>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            sx={[styles.offPrice, styles.priceXs]}
          >
            <Grid2 sx={styles.discountedPrice}>
              <mark
                css={[
                  styles.mark,
                  packsState.fullbody === "active" &&
                  packsState.aerobic === "active"
                    ? styles.activeMark
                    : styles.inactiveMark,
                ]}
              >
                599 هزار تومان
              </mark>
            </Grid2>
            <Grid2 sx={styles.originalPrice}>998 هزار تومان</Grid2>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"space-between"}
            sx={styles.buttonCntnr}
            alignItems={"center"}
          >
            <Grid2 container xs={12} md sx={styles.dateCntnr}>
              {packsState.fullbody === "active" &&
              packsState.aerobic === "active" &&
              packsData.aerobic.purchase_end_time ? (
                <>
                  <Grid2 xs={12}>
                    مهلت ثبت‌نام تا{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).day
                    }{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).month
                    }{" "}
                    ساعت{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).hour
                    }
                    :
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).minute
                    }
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).month}
                  </Grid2>
                </>
              ) : packsState.fullbody === "inactive" &&
                packsState.aerobic === "inactive" &&
                packsData.aerobic.purchase_start_time ? (
                <Grid2>
                  شروع ثبت‌نام از{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).day
                  }{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).month
                  }{" "}
                  ساعت{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).hour
                  }
                  :
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).minute
                  }
                </Grid2>
              ) : packsState.fullbody === "passed" &&
                packsState.aerobic === "passed" &&
                packsData.aerobic.start_date ? (
                <>
                  <Grid2 xs={12}>
                    <mark css={[styles.mark, styles.activeMark]}>
                      اتمام مهلت ثبت نام
                    </mark>
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).month}
                  </Grid2>
                </>
              ) : (
                <Grid2 xs={12}>به زودی ... </Grid2>
              )}
            </Grid2>
            {packsState.fullbody !== "inactive" &&
              packsState.aerobic !== "inactive" &&
              packsData.fullbody.instagram_id &&
              packsData.aerobic.instagram_id && (
                <Grid2
                  xs={12}
                  md
                  justifyContent={"center"}
                  sx={styles.pageIdCntnr}
                >
                  آیدی پیج باشگاه:
                  <Grid2 sx={styles.pageIdEngTxt}>
                    {packsData.fullbody.instagram_id} و{" "}
                    {packsData.aerobic.instagram_id}
                  </Grid2>
                </Grid2>
              )}
            <Grid2 container xs={12} md justifyContent={"flex-end"}>
              {packsState.fullbody === "active" &&
              packsState.aerobic === "active" ? (
                <Link to="/register?p=both" css={styles.link}>
                  <Button variant="contained" sx={styles.button}>
                    ثبت‌نام در هر دو باشگاه
                  </Button>
                </Link>
              ) : (
                <Button variant="contained" disabled sx={styles.button}>
                  ثبت‌نام در هر دو باشگاه
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2
          container
          xs={12}
          sx={[
            styles.packCntnr,
            { backgroundImage: `url(${Figure})` },
            packsState.fullbody === "active"
              ? styles.activePackCntnr
              : styles.inactivePackCntnr,
          ]}
          alignContent="flex-start"
          columnGap={6}
        >
          <Grid2
            container
            xs={12}
            alignItems="center"
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
          >
            <Grid2
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                whiteSpace: "pre-wrap",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <Grid2 xs={12} md>
                <h2 css={styles.packName}>
                  {packsData.fullbody.name
                    ? packsData.fullbody.name
                    : "فول بادی"}
                </h2>
              </Grid2>
              <Grid2
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  fontWeight: 600,
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  ml: { md: "0.5rem" },
                }}
              >
                + رژیم غذایی رایگان
              </Grid2>
            </Grid2>
            <Grid2 sx={[styles.priceTxt, styles.priceMd]}>499 هزار تومان</Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.aimTtl]}>
              هدف باشگاه:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              توی این باشگاه، هدف ما فرم دهی کل بدن، اصلاح فرم بدن و افزایش قوای
              جسمانی هستش.
            </Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.equipTtl]}>
              تجهیزات مورد نیاز:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              کش مینی لوپ
              <Grid2 xs={12} sx={styles.space} />
              خانم‌ها یک جفت دمبل با وزن بین 2 تا 6 کیلوگرم،
              <Grid2 xs={12} sx={styles.space} />
              آقایون یک جفت دمبل با وزن بین 5 تا 15 کیلوگرم
            </Grid2>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent="center"
            sx={[styles.priceTxt, styles.priceXs]}
          >
            499 هزار تومان
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={styles.buttonCntnr}
          >
            <Grid2 container xs={12} md sx={styles.dateCntnr}>
              {packsState.fullbody === "active" &&
              packsData.fullbody.purchase_end_time ? (
                <>
                  <Grid2 xs={12}>
                    مهلت ثبت‌نام تا{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.fullbody.purchase_end_time
                      ).day
                    }{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.fullbody.purchase_end_time
                      ).month
                    }{" "}
                    ساعت{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.fullbody.purchase_end_time
                      ).hour
                    }
                    :
                    {
                      numericDateTimeToAlphebet(
                        packsData.fullbody.purchase_end_time
                      ).minute
                    }
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.fullbody.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.fullbody.start_date).month}
                  </Grid2>
                </>
              ) : packsState.fullbody === "inactive" &&
                packsData.fullbody.purchase_start_time ? (
                <Grid2>
                  شروع ثبت‌نام از{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.fullbody.purchase_start_time
                    ).day
                  }{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.fullbody.purchase_start_time
                    ).month
                  }{" "}
                  ساعت{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.fullbody.purchase_start_time
                    ).hour
                  }
                  :
                  {
                    numericDateTimeToAlphebet(
                      packsData.fullbody.purchase_start_time
                    ).minute
                  }
                </Grid2>
              ) : packsState.fullbody === "passed" &&
                packsData.fullbody.start_date ? (
                <>
                  <Grid2 xs={12}>
                    <mark css={[styles.mark, styles.activeMark]}>
                      اتمام مهلت ثبت نام
                    </mark>
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.fullbody.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.fullbody.start_date).month}
                  </Grid2>
                </>
              ) : (
                <Grid2 xs={12}>به زودی ... </Grid2>
              )}
            </Grid2>
            {packsState.fullbody !== "inactive" &&
              packsData.fullbody.instagram_id && (
                <Grid2
                  xs={12}
                  md
                  justifyContent={"center"}
                  sx={styles.pageIdCntnr}
                >
                  آیدی پیج باشگاه:
                  <Grid2 sx={styles.pageIdEngTxt}>
                    {packsData.fullbody.instagram_id}
                  </Grid2>
                </Grid2>
              )}
            <Grid2 container xs={12} md justifyContent={"flex-end"}>
              {packsState.fullbody === "active" ? (
                <Link to="/register?p=fullbody" css={styles.link}>
                  <Button variant="contained" sx={styles.button}>
                    ثبت‌نام در باشگاه فول‌بادی
                  </Button>
                </Link>
              ) : (
                <Button variant="contained" disabled sx={styles.button}>
                  ثبت‌نام در باشگاه فول‌بادی
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2
          container
          xs={12}
          sx={[
            styles.packCntnr,
            { backgroundImage: `url(${Jump})` },
            packsState.aerobic === "active"
              ? styles.activePackCntnr
              : styles.inactivePackCntnr,
          ]}
          alignContent="flex-start"
          columnGap={6}
        >
          {/* <Grid2
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 css={styles.packName}>
              {packsData.aerobic.name
                ? packsData.aerobic.name + " + رژیم غذایی رایگان"
                : "هوازی + رژیم غذایی رایگان"}
            </h2> */}

          <Grid2
            container
            xs={12}
            alignItems="center"
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
          >
            <Grid2
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                whiteSpace: "pre-wrap",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <Grid2 xs={12} md>
                <h2 css={styles.packName}>
                  {packsData.aerobic.name ? packsData.aerobic.name : "فول بادی"}
                </h2>
              </Grid2>
              <Grid2
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  fontWeight: 600,
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  ml: { md: "0.5rem" },
                }}
              >
                + رژیم غذایی رایگان
              </Grid2>
            </Grid2>
            <Grid2 sx={[styles.priceTxt, styles.priceMd]}>499 هزار تومان</Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.aimTtl]}>
              هدف باشگاه:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              توی این باشگاه، هدف ما کاهش سایز، چربی‌سوزی و افزایش آمادگی جسمانی
              هستش.
            </Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.equipTtl]}>
              تجهیزات مورد نیاز:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              استپ (step)
            </Grid2>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent="center"
            sx={[styles.priceTxt, styles.priceXs]}
          >
            499 هزار تومان
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={styles.buttonCntnr}
          >
            <Grid2 container xs={12} md sx={styles.dateCntnr}>
              {packsState.aerobic === "active" &&
              packsData.aerobic.purchase_end_time ? (
                <>
                  <Grid2 xs={12}>
                    مهلت ثبت‌نام تا{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).day
                    }{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).month
                    }{" "}
                    ساعت{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).hour
                    }
                    :
                    {
                      numericDateTimeToAlphebet(
                        packsData.aerobic.purchase_end_time
                      ).minute
                    }
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).month}
                  </Grid2>
                </>
              ) : packsState.aerobic === "inactive" &&
                packsData.aerobic.purchase_start_time ? (
                <Grid2>
                  شروع ثبت‌نام از{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).day
                  }{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).month
                  }{" "}
                  ساعت{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).hour
                  }
                  :
                  {
                    numericDateTimeToAlphebet(
                      packsData.aerobic.purchase_start_time
                    ).minute
                  }
                  {/* ToDo: Remove static morning word */}
                </Grid2>
              ) : packsState.aerobic === "passed" &&
                packsData.aerobic.start_date ? (
                <>
                  <Grid2 xs={12}>
                    <mark css={[styles.mark, styles.activeMark]}>
                      اتمام مهلت ثبت نام
                    </mark>
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).day}{" "}
                    {numericDateToAlphebet(packsData.aerobic.start_date).month}
                  </Grid2>
                </>
              ) : (
                <Grid2 xs={12}>به زودی ... </Grid2>
              )}
            </Grid2>
            {packsState.aerobic !== "inactive" &&
              packsData.aerobic.instagram_id && (
                <Grid2
                  xs={12}
                  md
                  justifyContent={"center"}
                  sx={styles.pageIdCntnr}
                >
                  آیدی پیج باشگاه:
                  <Grid2 sx={styles.pageIdEngTxt}>
                    {packsData.aerobic.instagram_id}
                  </Grid2>
                </Grid2>
              )}
            <Grid2 container xs={12} md justifyContent={"flex-end"}>
              {packsState.aerobic === "active" ? (
                <Link to="/register?p=aerobic" css={styles.link}>
                  <Button variant="contained" sx={styles.button}>
                    ثبت‌نام در باشگاه هوازی
                  </Button>
                </Link>
              ) : (
                <Button variant="contained" disabled sx={styles.button}>
                  ثبت‌نام در باشگاه هوازی
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Grid2>

        {/* <Grid2
          container
          xs={12}
          sx={[
            styles.packCntnr,
            { backgroundImage: `url(${Elderly})` },
            packsState.corrective === "active"
              ? styles.activePackCntnr
              : styles.inactivePackCntnr,
          ]}
          alignContent="flex-start"
          columnGap={6}
        >
          <Grid2
            container
            xs={12}
            alignItems="center"
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
          >
            <Grid2
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                },
              }}
            >
              <h2 css={styles.packName}>
                {packsData.corrective.name
                  ? packsData.corrective.name + "\n+ رژیم غذایی رایگان"
                  : "تمرینات اصلاحی\n+ رژیم غذایی رایگان"}
              </h2>
            </Grid2>
            <Grid2 sx={{ display: { xs: "none", md: "flex" } }}>
              <h2 css={styles.packName}>
                {packsData.corrective.name
                  ? packsData.corrective.name + " + رژیم غذایی رایگان"
                  : "تمرینات اصلاحی + رژیم غذایی رایگان"}
              </h2>
            </Grid2>

            <Grid2 sx={[styles.priceTxt, styles.priceMd]}>499 هزار تومان</Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.aimTtl]}>
              هدف باشگاه:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              حرکات اصلاحی برای تقویت عضلات اطراف مفاصل جهت بهبود یا جلوگیری از
              آسیب ها + اصلاح فرم بدنی ( افتادگی شانه ، گودی کمر ، قوز و … )
              <br />
              مخصوص عزیزانی که آسیب مفصلی یا عضلانی دارن + سالمندان + و …
            </Grid2>
            <Grid2 xs={12} sx={[styles.desc, { fontWeight: 600 }]}>
              عزیزانی که دارای مشکلات جدی (مانند دیسک کمر، آرتروز و...)
              می‌باشند، با پزشک خود مشورت کنند.
            </Grid2>
          </Grid2>
          <Grid2 container xs={12} md alignContent="flex-start">
            <Grid2 xs={12} sx={[styles.descTitle, styles.equipTtl]}>
              تجهیزات مورد نیاز:
            </Grid2>
            <Grid2 xs={12} sx={styles.desc}>
              2 عدد کش مینی لوپ
            </Grid2>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent="center"
            sx={[styles.priceTxt, styles.priceXs]}
          >
            499 هزار تومان
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={styles.buttonCntnr}
          >
            <Grid2 container xs={12} md sx={styles.dateCntnr}>
              {packsState.corrective === "active" &&
              packsData.corrective.purchase_end_time ? (
                <>
                  <Grid2 xs={12}>
                    مهلت ثبت‌نام تا{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.corrective.purchase_end_time
                      ).day
                    }{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.corrective.purchase_end_time
                      ).month
                    }{" "}
                    ساعت{" "}
                    {
                      numericDateTimeToAlphebet(
                        packsData.corrective.purchase_end_time
                      ).hour
                    }
                    :
                    {
                      numericDateTimeToAlphebet(
                        packsData.corrective.purchase_end_time
                      ).minute
                    }
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.corrective.start_date).day}{" "}
                    {
                      numericDateToAlphebet(packsData.corrective.start_date)
                        .month
                    }
                  </Grid2>
                </>
              ) : packsState.corrective === "inactive" &&
                packsData.corrective.purchase_start_time ? (
                <Grid2>
                  شروع ثبت‌نام از{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.corrective.purchase_start_time
                    ).day
                  }{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.corrective.purchase_start_time
                    ).month
                  }{" "}
                  ساعت{" "}
                  {
                    numericDateTimeToAlphebet(
                      packsData.corrective.purchase_start_time
                    ).hour
                  }
                  :
                  {
                    numericDateTimeToAlphebet(
                      packsData.corrective.purchase_start_time
                    ).minute
                  }
                </Grid2>
              ) : packsState.corrective === "passed" &&
                packsData.corrective.start_date ? (
                <>
                  <Grid2 xs={12}>
                    <mark css={[styles.mark, styles.activeMark]}>
                      اتمام مهلت ثبت نام
                    </mark>
                  </Grid2>
                  <Grid2 xs={12}>
                    شروع باشگاه از{" "}
                    {numericDateToAlphebet(packsData.corrective.start_date).day}{" "}
                    {
                      numericDateToAlphebet(packsData.corrective.start_date)
                        .month
                    }
                  </Grid2>
                </>
              ) : (
                <Grid2 xs={12}>به زودی ... </Grid2>
              )}
            </Grid2>
            {packsState.corrective !== "inactive" &&
              packsData.corrective.instagram_id && (
                <Grid2
                  xs={12}
                  md
                  justifyContent={"center"}
                  sx={styles.pageIdCntnr}
                >
                  آیدی پیج باشگاه:
                  <Grid2 sx={styles.pageIdEngTxt}>
                    {packsData.corrective.instagram_id}
                  </Grid2>
                </Grid2>
              )}
            <Grid2 container xs={12} md justifyContent={"flex-end"}>
              {packsState.corrective === "active" ? (
                <Link to="/register?p=corrective" css={styles.link}>
                  <Button variant="contained" sx={styles.button}>
                    ثبت‌نام در باشگاه اصلاحی
                  </Button>
                </Link>
              ) : (
                <Button variant="contained" disabled sx={styles.button}>
                  ثبت‌نام در باشگاه اصلاحی
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Grid2> */}

        <Grid2 container xs={12} rowGap={1} sx={styles.psPrnt}>
          <Grid2 xs={12} sx={styles.psCntnr}>
            * <b>عزیزان خارج از کشور</b>، که امکان پرداخت به صورت ریالی ندارن،
            یا <b>دوستانی که در روند ثبت‌نام با مشکلی مواجه شدند</b>، به شماره
            تماس{" "}
            <a href="Tel: +989921078890" css={styles.telLink}>
              <span style={{ unicodeBidi: "bidi-override", direction: "ltr" }}>
                +989921078890
              </span>
            </a>{" "}
            در واتساپ پیغام بدن.
          </Grid2>
          {/* <Grid2 xs={12} sx={styles.psCntnr}> */}
          {/* ** دوستانی که امکان تهیه‌ی دمبل رو ندارن، میتونن از بطری آب که با شن
            یا ماسه پر شده استفاده کنن. */}
          {/* <Grid2 xs={12} sx={{ mt: "0.5rem" }} /> */}
          {/* <Grid2 sx={{ ml: "1.1rem" }}>
            همچنین برای عزیزانی که دسترسی به کش ندارن هم، حرکات جایگزین ارائه
            میشه.
          </Grid2> */}
          {/* </Grid2> */}
        </Grid2>
        <Guidelines packsData={packsData} />
      </Grid2>
    </Grid2>
  );
}
