import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";

const styles = {
  guidanceCntnr: {
    my: { xs: "2.5rem", md: "3rem" },
  },
  guidanceTtl: {
    color: "primary.main",
    fontSize: { xs: "1.875rem", sd: "2.5rem" },
    fontWeight: 750,
    mb: "1rem",
  },
  guidancePrgrph: {
    width: "100%",
    margin: "0.25rem 0 0.75rem 0",
  },
};


export default function Guidelines(props) {
  return (
    <Grid2 container xs={12} sx={styles.guidanceCntnr}>
      <Grid2
        container
        xs={12}
        sx={[styles.guidanceTtl, { display: { xs: "none", sm: "flex" } }]}
      >
        راهنمای ورود به باشگاه، بعد از ثبت نام
      </Grid2>
      <Grid2
        container
        xs={12}
        sx={[styles.guidanceTtl, { display: { xs: "flex", sm: "none" } }]}
      >
        راهنمای ورود به باشگاه،
        <br />
        بعد از ثبت نام
      </Grid2>

      <Grid2 container xs={12}>
        <p style={styles.guidancePrgrph}>
          با پیجی که آیدی آن را موقع ثبت نام وارد کردید پیج باشگاه مورد نظر:
        </p>
        <p style={styles.guidancePrgrph}>
          <ul style={{ margin: 0 }}>
            <li>
              فول بادی:{" "}
              {props.packsData.fullbody.instagram_id &&
                props.packsData.fullbody.instagram_id}
            </li>
            <li>
              هوازی:{" "}
              {props.packsData.aerobic.instagram_id && props.packsData.aerobic.instagram_id}
            </li>
            {/* <li>
             اصلاحی:{" "}
             {props.packsData.corrective.instagram_id &&
               props.packsData.corrective.instagram_id}
           </li> */}
          </ul>
        </p>
        <p style={styles.guidancePrgrph}>
          را فالو کرده و شماره ای که هنگام ثبت نام وارد کردید را در دایرکت پیج
          باشگاه ارسال کنید. سپس منتظر پاسخ و ورود به پیج باشید باشید و نگران
          نباشید؛ چون شما تا قبل شروع دوره وارد پیج باشگاه خواهید شد.
        </p>
        <p style={styles.guidancePrgrph}>
          توجه: در صورتی که آیدی پیج یا شماره ی خود را اشتباه وارد کنید وارد
          باشگاه نخواهید شد.
        </p>
      </Grid2>
    </Grid2>
  );
}
